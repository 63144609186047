import { getFeatureGates } from './getGatesClient'

export const trackEvent = (eventName, data) => {
  const featureGates = getFeatureGates()

  const marketingEvents = ['Form Value Changed', 'Form Value Focused']

  const identifiedData = {
    ...data,
    mvtest: featureGates,
  }

  if (typeof window !== 'undefined') {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ...identifiedData,
      })
    }

    if (window.heap) {
      window.heap.track(eventName, {
        ...identifiedData,
      })
    }

    if (window.datadogRum?.addAction) {
      if (!marketingEvents.includes(eventName)) {
        window.datadogRum.addAction(eventName, identifiedData)
      }
    }
  }
}

export const identify = async formData => {
  if (window.heap && formData.email) {
    window.heap.identify(formData.email)
  }
}
